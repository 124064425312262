<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :label="label" :hideLabel="hideLabel"
    :hasError="hasError" :errorMsg="errorMsg"
    :appendText="appendText" :appendAction="inputDateAppendAction"
    :prependText="prependText" :prependAction="prependAction"
    @toggleDatePicker="toggleDatePicker"
  >
    <template v-if="$slots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <template v-if="hasAppendSlot" #append><slot name="append"></slot></template>
    <template v-if="hasAppendContentSlot" #append-content><slot name="append-content"></slot></template>
    <template v-else-if="withIcon" #append-content><i class="fas fa-calendar-alt"></i></template>
    <template v-if="hasPrependSlot" #prepend><slot name="prepend"></slot></template>
    <template v-if="hasPrependContentSlot" #prepend-content><slot name="prepend-content"></slot></template>

    <template #default>
      <FormInputDate ref="input-date" :id="inputId" :min="min" :max="max" :format="format" :notRequired="notRequired" :disabled="disabled" :placeholder="placeholder" :value="value" @input="emitInput" />
    </template>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget.js';
import formInputDatePropsMixin from '../mixins/formInputDateProps.js';

import FormInputWidget from '../Widgets/FormInputWidget.vue';
import FormInputDate from '../FormInputDate.vue';

export default {
  mixins: [formInputWidgetMixin, formInputDatePropsMixin],
  components: {
    FormInputWidget,
    FormInputDate,
  },
  computed: {
    inputDateAppendAction () {
      if (!this.withIcon) {
        return null;
      }

      return 'toggleDatePicker';
    },
  },
  methods: {
    toggleDatePicker () {
      this.$refs['input-date'].toggleDatePicker();
    },
  },
};

</script>
