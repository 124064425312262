<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <div class="col-auto">
        <FormLabel label-is-fake not-required>Immagine dell'offerta</FormLabel>
        <div class="row no-gutters">
          <div class="col-auto">
            <ImagePreview instance="cover" v-for="file in uploaderFiles.cover" :image="file" :key="file.code" @remove="removeFile" @replace="replaceFile" />
          </div>
          <div class="col-auto">
            <Uploader ref="uploader-cover" instance="cover" :max="maxFiles.cover" v-model="uploaderFiles.cover" :uploadApiEndpoint="startUpload" :allowedFiles="['image/*']">
              Scegli immagine rappresentativa dell'offerta
            </Uploader>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg">
        <FormInputTextWidget
          label="Nome dell'offerta"
          v-model="item.name"
          :errorMsg="errorHash.name"
          @input="removeFieldError('name')"
        />

        <FormInputTextareaWidget
          label="Descrizione dell'offerta"
          v-model="item.description"
          :errorMsg="errorHash.description"
          @input="removeFieldError('description')"
        />
      </div>
    </div>

    <div class="row align-items-end">
      <FormInputCurrencyWidget
        :disabled="item.priceVariable"
        class="col-lg-6"
        label="Prezzo"
        v-model="item.price"
        :errorMsg="errorHash.price"
        @input="removeFieldError('price')"
      />

      <div class="col-lg-6 text-right">
        <button @click.prevent="showAdvanced = !showAdvanced" class="btn btn-outline-secondary">
          <template v-if="showAdvanced">Nascondi impostazioni avanzate</template>
          <template v-else>Mostra impostazioni avanzate</template>
        </button>
      </div>
    </div>

    <div class="mt-5 pt-3" style="border-top: 1px solid black" v-show="showAdvanced">
      <div class="row">
        <FormInputDateWidget
          class="col-lg-6"
          not-required
          label="Offerta vendibile dal"
          v-model="item.sellableSince"
          :errorMsg="errorHash.sellableSince"
          @input="removeFieldError('sellableSince')"
        />
        <FormInputDateWidget
          class="col-lg-6"
          not-required
          label="Offerta vendibile fino al"
          v-model="item.sellableUntil"
          :errorMsg="errorHash.sellableUntil"
          @input="removeFieldError('sellableUntil')"
        />
      </div>

      <div class="row">
        <FormInputCheckboxWidget
          class="col-lg-6"
          not-required
          v-model="item.priceVariable"
        >Il prezzo di questa offerta è dinamico?</FormInputCheckboxWidget>

        <FormInputCheckboxWidget
          class="col-12"
          not-required
          v-model="item.isPrivate"
        >Questa offerta è privata? (Le offerte private non vengono mostrate nella vetrina)</FormInputCheckboxWidget>

        <FormInputCheckboxWidget
          class="col-12"
          not-required
          v-model="item.sharingEnabled"
        >Mostrare le opzioni di condivisione dell'offerta?</FormInputCheckboxWidget>
      </div>

      <div class="row">
        <SelectAskForInvoiceDataWidget
          class="col-lg-6"
          label="Fatturazione"
          v-model="item.askForInvoiceData"
        />

        <FormInputWidget
          class="col-lg-6 invoice-with-widget"
          label-is-fake
          label="Come può essere richiesta la fattura elettronica?"
          :errorMsg="errorHash.allowInvoiceWith"
          errors-before-widget
        >
          <FormInputCheckbox not-required v-model="item.allowInvoiceWithSdi" @input="removeFieldError('allowInvoiceWith')">Codice SDI</FormInputCheckbox>
          <FormInputCheckbox not-required v-model="item.allowInvoiceWithPec" @input="removeFieldError('allowInvoiceWith')">Indirizzo PEC</FormInputCheckbox>
        </FormInputWidget>
      </div>

      <div class="row">
        <SelectAskForShippingDataWidget
          class="col-lg-6"
          label="Spedizione"
          v-model="item.askForShippingData"
        />

        <FormInputCurrencyWidget
          v-if="item.askForShippingData === 'optional'"
          class="col-lg-6"
          label="Costo di spedizione (Viene sommato al prezzo dell'offerta)"
          v-model="item.shippingCost"
          :errorMsg="errorHash.shippingCost"
          @input="removeFieldError('shippingCost')"
        />
      </div>

      <div class="row">
        <FormInputCheckboxWidget
          class="col-12"
          not-required
          v-model="item.redeemRequired"
        >Richiedere il riscatto del QR Code? (L'acquisto non sarà completato fino al riscatto del QR Code)</FormInputCheckboxWidget>

        <FormInputTextareaWidget
          v-if="item.redeemRequired"
          class="col-12"
          label="Indicazioni per il riscatto (inviate per email agli acquirenti)"
          v-model="item.redeemText"
          :errorMsg="errorHash.redeemText"
          @input="removeFieldError('redeemText')"
          not-required
        />
      </div>

      <div v-if="shop.bankTransferPossible" class="row">
        <FormInputCheckboxWidget
          class="col-12"
          not-required
          v-model="item.bankTransferEnabled"
        >Permettere il pagamento tramite bonifico bancario?</FormInputCheckboxWidget>

        <FormInputTextareaWidget
          v-if="item.bankTransferEnabled"
          class="col-12"
          label="Indicazioni per il pagamento con bonifico (mostrate nella vetrina e inviate per email agli acquirenti)"
          v-model="item.bankTransferInstructions"
          :errorMsg="errorHash.bankTransferInstructions"
          @input="removeFieldError('bankTransferInstructions')"
          not-required
        />
      </div>
    </div>

    <div class="row">
      <div class="col-auto mt-5">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
      <div class="col-auto mt-5" v-if="onOnboarding">
        <router-link :to="{ name: 'logout' }" class="btn btn-outline-secondary px-5">
          Esci e riprendi dopo
        </router-link>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';
import uploadFilesMixin from '@/libs/Uploader/mixins/uploadFiles.js';
import rolesMixin from '@/mixins/roles.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import SelectAskForShippingDataWidget from '@/views/components/Form/Widgets/SelectAskForShippingDataWidget.vue';
import SelectAskForInvoiceDataWidget from '@/views/components/Form/Widgets/SelectAskForInvoiceDataWidget.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputTextareaWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextareaWidget.vue';
import FormInputDateWidget from '@/libs/Form/components/Inputs/Widgets/FormInputDateWidget.vue';
import FormInputCurrencyWidget from '@/libs/Form/components/Inputs/Widgets/FormInputCurrencyWidget.vue';
import FormInputCheckboxWidget from '@/libs/Form/components/Inputs/Widgets/FormInputCheckboxWidget.vue';
import FormInputCheckbox from '@/libs/Form/components/Inputs/FormInputCheckbox.vue';
import FormLabel from '@/libs/Form/components/FormLabel.vue';
import FormInputWidget from '@/libs/Form/components/Inputs/Widgets/FormInputWidget.vue';
import Uploader from '@/libs/Uploader/components/Uploader.vue';
import ImagePreview from '@/libs/Uploader/components/ImagePreview.vue';

export default {
  mixins: [uploadFilesMixin, validateMixin, rolesMixin],
  components: {
    FormContainer,
    SelectAskForShippingDataWidget,
    SelectAskForInvoiceDataWidget,
    FormInputTextWidget,
    FormInputTextareaWidget,
    FormInputDateWidget,
    FormInputCurrencyWidget,
    FormInputCheckboxWidget,
    FormInputCheckbox,
    FormLabel,
    FormInputWidget,
    Uploader,
    ImagePreview,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        name: null,
        description: null,
        askForShippingData: 'no',
        askForInvoiceData: 'optional',
        shippingCost: 0,
        priceVariable: false,
        price: null,
        sellableSince: null,
        sellableUntil: null,
        sharingEnabled: true,
        redeemRequired: true,
        redeemText: null,
        allowInvoiceWithSdi: true,
        allowInvoiceWithPec: true,
        cover: null,
        bankTransferEnabled: false,
        bankTransferInstructions: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    onOnboarding: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      showAdvanced: false,
      uploader: [
        { name: 'cover', fixedMaxFiles: 1 },
      ],
      uploaderFiles: {
        cover: this.item.cover ? [{ ...this.item.cover }] : [],
      },
    };
  },
  computed: {
    shop () {
      return this.$oauth2?.me?.shop || {};
    },
  },
  watch: {
    'item.cover': function (newValue) {
      this.uploaderFiles.cover = newValue ? [{ ...newValue }] : [];
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.item.name) {
        this.addError('Inserisci il nome', 'name');
      }

      if (!this.item.description) {
        this.addError('Inserisci la descrizione', 'description');
      }

      if (!this.item.priceVariable && !this.item.price) {
        this.addError('Inserisci il prezzo', 'price');
      }

      if (this.item.sellableSince && this.item.sellableUntil && this.item.sellableUntil < this.item.sellableSince) {
        this.addError('La data di inizio vendita dell\'offerta supera quella di fine vendita', 'sellableUntil');
        this.showAdvanced = true;
      }

      if (!this.item.allowInvoiceWithSdi && !this.item.allowInvoiceWithPec) {
        this.addError('Selezionare almeno un\'opzione', 'allowInvoiceWith');
        this.showAdvanced = true;
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      if (!this.uploadsFinishedAndReadyToSubmitInInstance('cover')) {
        this.addError('cover', 'Caricamento immagine in corso');

        return;
      }

      const itemToReturn = { ...this.item };
      itemToReturn.image = this.uploaderFilesToSubmit.cover.length > 0 ? { ...this.uploaderFilesToSubmit.cover[0] } : null;
      if (!itemToReturn.redeemRequired) {
        itemToReturn.redeemText = null;
      }

      this.$emit('submitForm', itemToReturn);
    },
  },
};

</script>

<style lang="scss">

.invoice-with-widget {
  .form-feedback-message {
    margin-bottom: 0;
  }
}

</style>
