<template>
  <div class="flex-grow-1" @click.prevent>
    <Datepicker ref="date-picker" class="form-control" :class="{ disabled }" :language="it" :monday-first="true" :format="format" :disabledDates="disabledDates" :clearButton="true" :id="inputId" :required="!notRequired" :disabled="disabled" :readonly="disabled" :placeholder="placeholder" :value="value" @input="$emit('input', $event)" />
  </div>
</template>

<script>

import formInputFieldMixin from './mixins/formInputField.js';
import formInputDatePropsMixin from './mixins/formInputDateProps.js';
import formInputTextPropsMixin from './mixins/formInputTextProps.js';
import Datepicker from 'vuejs-datepicker';
import { it } from 'vuejs-datepicker/dist/locale';

export default {
  mixins: [formInputFieldMixin, formInputTextPropsMixin, formInputDatePropsMixin],
  components: {
    Datepicker,
  },
  data: () => ({ it }),
  computed: {
    disabledDates () {
      return {
        from: this.max ? this.max : null,
        to: this.min ? this.min : null,
      };
    },
  },
  methods: {
    toggleDatePicker () {
      const ref = this.$refs['date-picker'];
      if (!ref) {
        return;
      }

      const input = ref.$el.querySelector('input');
      if (!input) {
        return;
      }

      input.click();
      input.focus();
    },
  },
};

</script>

<style lang="scss">

.vdp-datepicker {
  padding: 0;

  & > div:first-child {
    height: 100%;
    position: relative;

    input {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0.375rem 0.75rem;
    }

    .vdp-datepicker__clear-button {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.disabled {
    .vdp-datepicker__clear-button {
      display: none;
    }
  }
}

</style>
