import formInputFieldPropsMixin from './formInputFieldProps.js';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    min: {
      required: false,
      type: Date,
      default: () => null,
    },
    max: {
      required: false,
      type: Date,
      default: () => null,
    },
    format: {
      type: String,
      default: 'dd MMM yyyy',
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
  },
};
