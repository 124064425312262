<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :hasError="hasError" :errorMsg="errorMsg"
  >
    <template v-if="$slots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <FormInputCheckbox :id="inputId" :notRequired="notRequired" :placeholder="placeholder" :disabled="disabled" :value="value" :checked="checked" @input="emitInput">
      <template #default>
        <slot></slot>
      </template>
    </FormInputCheckbox>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget.js';
import formInputCheckboxPropsMixin from '../mixins/formInputCheckboxProps.js';

import FormInputWidget from '../Widgets/FormInputWidget.vue';
import FormInputCheckbox from '../FormInputCheckbox.vue';

export default {
  mixins: [formInputWidgetMixin, formInputCheckboxPropsMixin],
  components: {
    FormInputWidget,
    FormInputCheckbox,
  },
};

</script>
