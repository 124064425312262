<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="mediaTypes" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

import FormInputSelectWidget from '@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget,
  },
  data: () => ({
    mediaTypes: [
      { value: 'optional', label: 'Opzionale' },
      { value: 'required', label: 'Obbligatoria' },
    ],
  }),
};

</script>
